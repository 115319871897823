import { Space, Select, Button } from 'antd'
import { GoogleMap, LoadScript } from '@react-google-maps/api'
import React, { useState } from 'react'
import logo from 'Images/logo.svg'
import styled from 'styled-components'
import { AimOutlined } from '@ant-design/icons'
import { user } from 'Models/UserModel/UserModel'
const Option = Select

const containerStyle = {
  width: '100%',
  height: '100%',
  // display: 'none'
}

const center = {
  lat: -3.745,
  lng: -38.523,
}

const Bottom = styled(Space)`
  position: absolute;
  display: flex;
  flex-direction: row;
  left: 20px;
  bottom: 20px;
`

const Logo = styled.img`
  width: 40px;
  height: 40px;

  transition: 300ms all;

  &:hover {
    transform: translateY(-8px) scale(1.1);
  }
`

const PlaceButton = styled.div`
  background-color: transparent;
`

let Places = [
  {
    name: 'Phnom Penh',
    code: '',
    coordinate: {
      lat: 11.56293,
      lng: 104.917379,
    },
  },
  {
    name: 'Sihanoukville',
    code: '18',
    coordinate: {
      lat: 10.6343527660001,
      lng: 103.503359524,
    },
  },
  {
    name: 'Kampong Cham',
    code: '3',
    coordinate: {
      lat: 11.992446,
      lng: 105.464592,
    },
  },
  {
    name: 'Ang Snoul',
    code: '801',
    coordinate: {
      lat: 11.5074656,
      lng: 104.7160366,
    },
  },
  {
    name: 'Battambang',
    code: '203',
    coordinate: {
      lat: 13.090358,
      lng: 103.190498,
    },
  },
  {
    name: 'Kampong Chhnang',
    code: '403',
    coordinate: {
      lat: 12.2611921,
      lng: 104.673647,
    },
  },
  // {
  //   name: 'Siem Reap',
  //   coordinate: {
  //     lat: 13.36179,
  //     lng: 103.86056,
  //   },
  // },
  // {
  //   name: 'Kampot',
  //   coordinate: {
  //     lat: 10.61041,
  //     lng: 104.18145,
  //   },
  // },
  // {
  //   name: 'Kampong Thom',
  //   coordinate: {
  //     lat: 12.71112,
  //     lng: 104.88873,
  //   },
  // },
  // {
  //   name: 'Banteay Meanchey',
  //   coordinate: {
  //     lat: 13.65611,
  //     lng: 102.5625,
  //   },
  // },
]

function MyComponent(props: any) {
  const isOverlap: boolean | undefined = props.isOverlap
  const disableSelector: boolean = Boolean(props.disableSelector)

  // const defaultLocation = Places.find(
  //   (place) => place.code === user?.province_code
  // )

  const [map, setMap] = useState({
    center: {
      lat: () => 0,
      lng: () => 0,
    },
    panTo: (location: any) => {},
  })

  // if (defaultLocation) {
  //   props?.onPlaceSelected && props?.onPlaceSelected(defaultLocation)
  //   map.panTo(defaultLocation.coordinate)
  // }

  function onMapLoaded(mapInstance: any) {
    setMap(mapInstance)
    props.onLoad && props.onLoad(mapInstance)
  }

  function onPlaceClick(place: any) {
    props?.onPlaceSelected && props?.onPlaceSelected(place)
    map.panTo(place.coordinate)
  }

  const handleCurrentLocationClick = () => {
    navigator.geolocation.getCurrentPosition(function (position) {
      map.panTo({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      })
    })
  }

  return isOverlap ? (
    <LoadScript googleMapsApiKey="AIzaSyA9QqMeC8rDUNGXmj6fENE3TJy7Ou_kN14">
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={10}
        {...props}
        onLoad={onMapLoaded}
      >
        {props.children}
      </GoogleMap>
      {!disableSelector && (
        <Bottom>
          <Logo src={logo}></Logo>
          <Select style={{ width: 140 }}>
            {Places.map((place) => {
              return (
                <Option key={place.name} value={place.name}>
                  <PlaceButton onClick={() => onPlaceClick(place)}>
                    {place.name}
                  </PlaceButton>
                </Option>
              )
            })}
          </Select>
          <Button
            icon={<AimOutlined />}
            onClick={handleCurrentLocationClick}
          ></Button>
        </Bottom>
      )}
    </LoadScript>
  ) : (
    <LoadScriptOnlyIfNeeded googleMapsApiKey="AIzaSyA9QqMeC8rDUNGXmj6fENE3TJy7Ou_kN14">
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={10}
        {...props}
        onLoad={onMapLoaded}
      >
        {props.children}
      </GoogleMap>
      {!disableSelector && (
        <Bottom>
          <Logo src={logo}></Logo>
          <Select defaultValue={Places[1].name} style={{ width: 140 }}>
            {Places.map((place) => {
              return (
                <Option key={place.name}>
                  <PlaceButton onClick={() => onPlaceClick(place)}>
                    {place.name}
                  </PlaceButton>
                </Option>
              )
            })}
          </Select>
          <Button
            icon={<AimOutlined />}
            onClick={handleCurrentLocationClick}
          ></Button>
        </Bottom>
      )}
    </LoadScriptOnlyIfNeeded>
  )
}

// @todo: https://github.com/JustFly1984/react-google-maps-api/issues/70
// see: https://github.com/JustFly1984/react-google-maps-api/issues/159#issuecomment-502446663
class LoadScriptOnlyIfNeeded extends LoadScript {
  componentDidMount() {
    const cleaningUp = true
    const isBrowser = typeof document !== 'undefined' // require('@react-google-maps/api/src/utils/isbrowser')
    const isAlreadyLoaded =
      (window as any).google &&
      (window as any).google.maps &&
      // very gheto to use querySelector
      document.querySelector('body.first-hit-completed') // AJAX page loading system is adding this class the first time the app is loaded
    if (!isAlreadyLoaded && isBrowser) {
      // @ts-ignore
      if (window.google && !cleaningUp) {
        console.error('google api is already presented')
        return
      }

      this.isCleaningUp().then(this.injectScript)
    }

    if (isAlreadyLoaded) {
      this.setState({ loaded: true })
    }
  }
}

export const Map = React.memo(MyComponent)
