// @ts-nocheck
import React, { useEffect, useState } from 'react'
import { PDFDownloadLink } from '@react-pdf/renderer'
import {
  EditButton as BaseEditButton,
  PropertyTypeDisplay,
  Button,
} from 'Components'
import { DownloadOutlined } from '@ant-design/icons'
import { IProperty } from 'Models'
import { PropertyEditContainer } from '../Edit'
import { QRCode } from 'react-qrcode-logo'
import { Space, Image as ImageComponent } from 'antd'
import logoImage from 'Images/logo.svg'
import moment from 'moment'
import styled from 'styled-components'
import { thousandSeparator } from 'Utils/Currency'
import imageNotFound from '../assets/ImageNotFound.png'
import { PDFDoc } from './PropertyPDF'
import { getImage } from 'Utils/files'
import { renderStatus } from 'Utils/property'
import { encrypt } from 'Utils/secret'
import { hasPermission } from 'Auth/Authorization'
import { showAllPropertyField } from 'Auth/Role'
import { propertyTypeStore } from 'Models/PropertyTypeModel/PropertyTypeModel'
import _ from 'lodash'

const Container = styled.div``

const Row = styled.div`
  display: flex;
  flex-direction: row;
`

const Title = styled.h3`
  display: flex;
  align-self: center;
  justify-content: center;
`

const DownloadButton = styled(Button)`
  margin-left: auto;
  margin-right: 26px;
  & > a {
    color: white !important;
  }
`

const EditButton = styled(BaseEditButton)`
  margin-left: auto;
`

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
`

const ImageWrapper = styled(Center)`
  margin-top: 1rem;
  margin-bottom: 1rem;
`

const Tr = styled.tr`
  height: 40px;
`

interface Props {
  property: IProperty
  onEditClick?: () => void
}

const optionFields = {
  Bars: 'បារ',
  Floors: 'ជាន់',
  Restaurants: 'អាហារដ្ឋាន',
  Room: 'បន្ទប់',
  Floor_Area: 'ផ្ទៃជាន់',
  Gym: 'កន្លែងហាត់ប្រាណ',
  Bed: 'គ្រែ',
  Land_Size: 'ទំហំដី',
  BuildingSize: 'ទំហំអាគារ',
  Capacity: 'ទំហំផ្ទុក',
  TableCount: 'ចំនួនតុ',
  BuildingCount: 'ចំនួនអគារ',
  Cafes: 'ហាងកាហ្វេ',
  Building: 'អគារ',
  Chairs: 'កៅអុី',
  Businesses: 'អាជីវកម្ម',
  Marts: 'ម៉ាត',
}

const suffixOptionFields = {
  Bars: '',
  Floors: '',
  Restaurants: '',
  Room: 'បន្ទប់',
  Floor_Area: 'ម',
  Gym: '',
  Bed: '',
  Land_Size: 'ម',
  BuildingSize: 'ម',
  Capacity: '',
  TableCount: '',
  BuildingCount: '',
  Cafes: '',
  Building: '',
  Chairs: '',
  Businesses: '',
  Marts: '',
}

export const PropertyDetail = (props: Props) => {
  const { property } = props
  const [showEdit, setShowEdit] = useState(false)
  const [qrUri, setQrUri] = useState<string>()
  const [detailQrUri, setDetailQrUri] = useState<string>()
  // console.log('qrUri: ', qrUri)

  let propertyTypeName = _.find(propertyTypeStore.propertyTypes, (type) => {
    return type?.code === property?.Type
  })

  useEffect(() => {
    setTimeout(() => {
      // Without timeout, the logo inside qr code is not rendered in pdf
      const qrCodeCanvas = document.querySelector(
        '#react-qrcode-logo'
      ) as HTMLCanvasElement
      if (!qrCodeCanvas) {
        return
      }
      const qrCodeDataUri = qrCodeCanvas.toDataURL('image/jpg', 0.9)
      setQrUri(qrCodeDataUri)

      const detailQrCodeCanvas = document.querySelector(
        '#react-detail-qrcode-logo'
      ) as HTMLCanvasElement
      if (!detailQrCodeCanvas) {
        return
      }
      const detailQrCodeDataUri = detailQrCodeCanvas.toDataURL('image/jpg', 0.9)
      setDetailQrUri(detailQrCodeDataUri)
    }, 100)
  }, [property])

  function onEditClick() {
    setShowEdit(true)
    props.onEditClick && props.onEditClick()
  }

  if (showEdit) {
    return (
      <Container>
        <PropertyEditContainer
          property={property}
          onSaveFinish={() => setShowEdit(false)}
        ></PropertyEditContainer>
      </Container>
    )
  }

  // const renderStatus = (property: IProperty) => {
  //   if (property.IsSuspense === 1) {
  //     return <InActivePropertyStatus>បានផ្អាកបណ្តោះអាសន្ន (Suspended)</InActivePropertyStatus>
  //   } else if (property.IsActive === 1) {
  //     return <ActivePropertyStatus>សកម្ម</ActivePropertyStatus>
  //   } else if (property.IsActive === 2) {
  //     return <>
  //       <PreActivePropertyStatus>កំពុងចរចារ</PreActivePropertyStatus>
  //     </>
  //   }

  //   return <InActivePropertyStatus>អសកម្ម</InActivePropertyStatus>
  // }

  const renderOptionalField = (label: any, value: string, suffix: string) => {
    return (
      <Tr>
        <td>{label}</td>
        <td>
          {suffix === 'ម' ? (
            <span>{value} ម&sup2;</span>
          ) : (
            <span>
              {value} {suffix}
            </span>
          )}
        </td>
      </Tr>
    )
  }

  const shouldRenderProperty = (fieldName: string) => {
    // @ts-ignore
    return (
      property &&
      property[fieldName] !== undefined &&
      property[fieldName] !== null &&
      property[fieldName] !== 0
    )
  }

  return (
    <Container>
      <Row>
        <Space style={{ marginLeft: 'auto' }}>
          <EditButton type="primary" onClick={onEditClick}></EditButton>

          <DownloadButton type="primary" icon={<DownloadOutlined />}>
            {qrUri && detailQrUri && (
              <PDFDownloadLink
                document={
                  <PDFDoc
                    property={property}
                    qrUri={qrUri}
                    detailQrUri={detailQrUri}
                  />
                }
                fileName={`property-${property.OBJECTID}.pdf`}
              >
                {({ blob, url, loading, error }) => {
                  console.log('error ===>', error)
                  console.log('loading ===>', loading)

                  return loading ? 'កំពុងផ្ទុក...' : ' PDF'
                }}
              </PDFDownloadLink>
            )}
          </DownloadButton>
        </Space>
      </Row>
      <ImageWrapper>
        {property.Photo && (
          <ImageComponent
            height={200}
            src={getImage(property.Photo || '') ?? imageNotFound}
          />
        )}
      </ImageWrapper>
      <table className="styled-table">
        <tbody>
          <Tr>
            <td>Property Id:</td>
            <td>{property.OBJECTID}</td>
          </Tr>
          <Tr>
            <td>Status:</td>
            <td>{renderStatus(property)}</td>
          </Tr>
          {property.IsActive === 2 ? (
            <Tr>
              <td>Note: </td>
              <td>{property?.IsActiveNote}</td>
            </Tr>
          ) : (
            ''
          )}
          <Tr>
            <td>Property: </td>
            <td>{property.Isclosed === 1 ? 'Closed' : 'Open as normal'}</td>
          </Tr>
          <Tr>
            <td>Name:</td>
            <td>{property.Name}</td>
          </Tr>
          <Tr>
            <td>Citizen name:</td>
            <td>{property.OwnerName}</td>
          </Tr>
          <Tr>
            <td>Owner phone:</td>
            <td>{property.OwnerPhone}</td>
          </Tr>
          <Tr>
            <td>Address:</td>
            <td>{property.FormattedAddress}</td>
          </Tr>
          <Tr>
            <td>Province:</td>
            <td>{property.Province}</td>
          </Tr>
          <Tr>
            <td>Business status:</td>
            <td>{property.BusinessStatus}</td>
          </Tr>
          <Tr>
            <td>Collector:</td>
            <td>{property.Collector}</td>
          </Tr>

          {hasPermission(showAllPropertyField) && (
            <Tr>
              <td>Property Type:</td>
              <td>
                {/* <PropertyTypeDisplay
                  code={property.Type + ''}
                ></PropertyTypeDisplay> */}
                {propertyTypeName?.name_khmer || 'N/A'}
              </td>
            </Tr>
          )}

          {hasPermission(showAllPropertyField) &&
            Object.keys(optionFields).map((key, index) => {
              if (shouldRenderProperty(key)) {
                // @ts-ignore
                return renderOptionalField(
                  optionFields[key],
                  property[key],
                  suffixOptionFields[key]
                )
              }
            })}
          {/* <Tr>
            <td>Restaurants:</td>
            <td>{property.Restaurants}</td>
          </Tr>
          <Tr>
            <td>Room:</td>
            <td>{property.Room}</td>
          </Tr>
          <Tr>
            <td>Bars:</td>
            <td>{property.Bars}</td>
          </Tr>
          <Tr>
            <td>Beds:</td>
            <td>{property.Bed}</td>
          </Tr> 
          <Tr>
            <td>Gyms:</td>
            <td>{property.Gym}</td>
          </Tr>
          <Tr>
            <td>Land Size:</td>
            <td>{property.Land_Size} ម<sup>២</sup></td>
          </Tr>
          <Tr>
            <td>Building Size</td>
            <td>{property.BuildingSize} ម<sup>២</sup></td>
          </Tr> */}
          <Tr>
            <td>Start service at:</td>
            <td>
              {property.ActivateDate &&
                moment(property.ActivateDate).format('YYYY-MM-DD')}
            </td>
          </Tr>
          <Tr>
            <td>Old Fee:</td>
            <td>{thousandSeparator(property.OldFee || 0)}៛</td>
          </Tr>
          <Tr>
            <td>Collection Fee:</td>
            <td>{thousandSeparator(property.CollectionFee)}៛</td>
          </Tr>
          <Tr>
            <td>Final Fee (Discounted fee):</td>
            <td>
              {property.DiscountedFee
                ? thousandSeparator(property.DiscountedFee) + '៛'
                : 'N/A'}
            </td>
          </Tr>
          <Tr>
            <td>Last Billed Date:</td>
            <td>
              {property.LastBilledDate &&
                moment(property.LastBilledDate).format('YYYY-MM-DD')}
            </td>
          </Tr>
          <Tr>
            <td>Note:</td>
            <td>{property.Note}</td>
          </Tr>
        </tbody>
      </table>
      <Center className="qr">
        <div>
          <QRCode
            // @ts-ignore
            value={`https://www.google.com/maps/search/?api=1&query=${property.geometry?.lat},${property.geometry?.lng}`}
            logoImage={logoImage}
            qrStyle="dots"
            size={200}
          ></QRCode>
          <Title>QR code មើលទីតាំង</Title>
        </div>
        <div>
          <QRCode
            // @ts-ignore
            id="react-detail-qrcode-logo"
            value={`https://qr.samraam.com/properties?code=${encrypt(
              property.OBJECTID
            )}`}
            logoImage={logoImage}
            qrStyle="dots"
            size={200}
          ></QRCode>

          <Title>QR code ព័ត៌មានលំអិត</Title>
        </div>
      </Center>
    </Container>
  )
}
