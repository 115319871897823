import { AccessRules, Pages, hasAccess } from 'Auth/Role'
import { Layout, Menu } from 'antd'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import Logo from 'Images/swm.svg'
import styled from 'styled-components'
import { user } from 'Models/UserModel/UserModel'

const LogoImage = styled.img`
  width: 100px;
`

const { Header } = Layout
const { SubMenu } = Menu

export const pages = [
  {
    id: Pages.ASSIGN_PROPERTY_TO_ROUTE_PAGE,
    key: '/route-property',
    label: 'Property & Schedule',
  },
  {
    id: Pages.PROPERTY_ACTIVATION_PAGE,
    key: '/property-activation',
    label: 'Property Activation',
  },
  {
    id: Pages.SCHEDULE_PAGE,
    key: '/schedules',
    label: 'Schedule',
  },
  {
    id: Pages.TRUCK_PAGE,
    key: '/route-truck',
    label: 'Truck ',
  },
  // {
  //   id: Pages.COLLECTION_POINTS_PAGE,
  //   key: '/collection-points',
  //   label: 'Collecton Points',
  // },
  {
    id: Pages.CITIZEN_PROFILE_PAGE,
    key: '/citizens',
    label: 'Citizen',
  },
  {
    id: Pages.PROPERTY_PAGE,
    key: '/property',
    label: 'Property',
  },
  {
    id: Pages.LIST_ALL_PROPERTY,
    key: '/all-property',
    label: 'All Property',
  },
  {
    id: Pages.CHECK_LOGS,
    key: '/check-logs',
    label: 'Check logs',
  },
  {
    id: Pages.PROPERTY_BILL_PAGE,
    key: '/route-property-bill',
    label: 'Billing by route',
  },
  {
    id: Pages.DAILY_REPORT_PAGE,
    key: '/daily-report',
    label: 'Daily Report',
  },
  {
    id: Pages.DISTRIBUTE_REPORT,
    key: '/distribute-report',
    label: 'Distribute Report',
  },
  {
    id: Pages.BILLING_PAGE,
    key: '/billing',
    label: 'Billing',
  },
  {
    id: Pages.GENERATE_BILL,
    key: '/generate-bill',
    label: 'Generate bill',
  },
  // {
  //   id: Pages.BUSINESS_PAGE,
  //   key: '/business',
  //   label: 'Business',
  // },
  {
    id: Pages.PROPERTY_TYPE_PAGE,
    key: '/property-types',
    label: 'តារាងឧបសម្ព័ន្ធ',
  },
  {
    id: Pages.COLLECTION_COMPANY_PAGE,
    key: '/collection-companies',
    label: 'Collection Company',
  },
  {
    id: Pages.SHV_BILLING,
    key: '/shv-billing',
    label: 'SHV Billing',
  },
  {
    id: Pages.COLLECTOR_PAGE,
    key: '/collector',
    label: 'Collector',
  },
  {
    id: Pages.USER_PAGE,
    key: '/users',
    label: 'Users',
  },
  {
    id: Pages.IMPORTED_DATA,
    key: '/imported-data',
    label: 'Imported Data',
  },
  {
    id: Pages.REPORT_PAGE,
    key: '/reports',
    label: 'Reports',
  },
]

interface Props {}

export const HeaderSection = (props: Props) => {
  const [selectedKey, setSelectedKey] = useState('/route-property')
  let history = useHistory()
  let location = useLocation()

  useEffect(() => {
    const { pathname } = location
    setSelectedKey(pathname)
  }, [location])

  function onMenuItemClick(key: string) {
    setSelectedKey(key)
    history.push(key)
  }

  function onLogoutClick() {
    user.logout()
  }

  if (!user.isLoggedIn) {
    return null
  }

  return (
    <Header style={{ zIndex: 1, width: '100%' }}>
      <Menu theme="dark" mode="horizontal" selectedKeys={[selectedKey]}>
        <Menu.Item key="logo" onClick={() => onMenuItemClick('/')}>
          <LogoImage src={Logo} alt="logo" />
        </Menu.Item>

        {pages.map((page) => {
          if (!hasAccess(page.id)) {
            return null
          }
          return (
            <Menu.Item key={page.key} onClick={() => onMenuItemClick(page.key)}>
              {page.label}
            </Menu.Item>
          )
        })}

        <SubMenu
          style={{ float: 'right' }}
          key="user"
          title={`👤 ${user.name}`}
        >
          <Menu.Item onClick={onLogoutClick} key="logout">
            Logout
          </Menu.Item>
        </SubMenu>
      </Menu>
    </Header>
  )
}
